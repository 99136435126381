import { nanoid } from "nanoid";

export const ASYNC_ACTION_PREFIX = nanoid(11) + "/";

export const createAsyncTypes = <T extends string>(type: T) => {
  return {
    REQUEST: ASYNC_ACTION_PREFIX + type + "/REQUEST",
    SUCCESS: ASYNC_ACTION_PREFIX + type + "/SUCCESS",
    FAILURE: ASYNC_ACTION_PREFIX + type + "/FAILURE",
  };
};
