import '~/styles/index.scss';
import "@google/model-viewer/lib/model-viewer";
import axios from 'axios';
import { h, render } from "preact";
import { GRAPHQL_HOST } from '~/cores/constants';
import { Provider } from 'react-redux';
import { configureStore } from "~/cores/createStore";
import App from "~/components-outdata/App";

axios.defaults.baseURL = GRAPHQL_HOST;
axios.defaults.withCredentials = true;

const store = configureStore();

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.body
);