import { FunctionComponent, h } from 'preact';
import { Router } from 'preact-router';
import Viewer from './Viewer';

interface Props {

}

const App: FunctionComponent<Props> = () => {
  return (
    <Router>
      <Viewer path="/:hashId*" />
    </Router>
  )
};

export default App;
