import produce from 'immer';
import axios, { AxiosResponse } from "axios";
import { handleActions } from 'redux-actions';
import { createAsyncAction } from "~/cores/createAsyncAction";
import { createAsyncReducerMap } from "~/cores/createAsyncReducerMap";
import { GET_ANONYMOUS_SCENE } from "~/cores/queries";
import { Scene } from "~/cores/schema";
import { decryptGltf } from "~/cores/api";

export enum SceneType {
  GET_MODEL = '@scene/GET_MODEL',
  GET_ANONYMOUS_SCENE = '@scene/GET_ANONYMOUS_SCENE',
}

export interface SceneState {
  scene: Scene | null;
  model: string | null;
}

export const SceneActions = {
  getAnonymousScene: createAsyncAction(SceneType.GET_ANONYMOUS_SCENE, (hashId: string) => {
    return axios.post('/graphql', { query: GET_ANONYMOUS_SCENE, variables: { hashId } });
  }),
  getModel: createAsyncAction(SceneType.GET_MODEL, async (url: string, secret: string, iv: string) => {
      try {
        const { data } = await axios.get(url, {withCredentials: false})
        return await decryptGltf(data, secret, iv);
      } catch (e) {
        throw e;
      }
  }),
};

const initialState: SceneState = {
  scene: null,
  model: null
};

export default handleActions<SceneState, any>(
  {
    ...createAsyncReducerMap<SceneState, string>(SceneType.GET_MODEL, {
      onSuccess: (state, { payload  }) => {
        return produce(state, draft => {
          draft.model = payload;
        });
      }
    }),
    ...createAsyncReducerMap<SceneState, AxiosResponse<{ data: { getAnonymousScene: Scene } }>>(SceneType.GET_ANONYMOUS_SCENE, {
      onSuccess: (state, { payload }) => {
        return produce(state, draft => {
          draft.scene = payload.data.data.getAnonymousScene;
        });
      },
    }),
  },
  initialState
);