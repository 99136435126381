import { h, FunctionComponent, Fragment } from "preact";
import { RoutableProps, RouterProps } from "preact-router";
import { useEffect, useMemo, useState } from "preact/hooks";
import {
  createHit,
  decryptGltf,
  getAnonymousScene,
  loadGltf,
} from "../cores/api";
import { Asset, AssetGroup, Scene } from "../cores/schema";
import path from "path";
import ModelViewer from "./ModelViewer";
import { getLastAssetGroup } from "../cores/getLastAssetGroup";
import get from 'lodash.get';
import isMobile from 'is-mobile';
import QRCode from 'qrcode.react';
import styles from './Viewer.module.scss';
import Ink from 'react-ink';
import { AiOutlineQrcode } from 'react-icons/ai';
import classnames from 'classnames';
import MobileDetect from 'mobile-detect';
import copy from 'copy-to-clipboard';
interface Props {
  hashId?: string;
  matches?: { [key: string]: string };
}

const Viewer: FunctionComponent<RoutableProps & Props> = ({ hashId, matches }) => {
  const [scene, setScene] = useState<Scene | null>(null);
  const [source, setSource] = useState<string | null>(null);
  const [visibleQrcode, setVisibleQrcode] = useState(false);
  const [copied, setCopied] = useState(false);

  const isAvailableCreateHit = useMemo(() => get(matches, 'is_available_create_hit') !== 'false', [matches]);
  const mobileDetect = useMemo(() => new MobileDetect(window.navigator.userAgent), []);
  const isDirectOpen = useMemo(() => get(matches, 'is_direct_open') === 'true', [matches]);
  const isDesktop = useMemo(() => !isMobile(), []);
  const isSuspendUsdz = useMemo(() => mobileDetect.os() === 'iOS' && mobileDetect.userAgent() === 'Chrome', [mobileDetect])
  const isAndroid = useMemo(() => mobileDetect.os() === 'AndroidOS', [mobileDetect]);

  useEffect(() => {
    if (!hashId) {
      return;
    }

    (async () => {
      if (isAvailableCreateHit) {
        createHit(hashId);
      }

      const data = await getAnonymousScene(hashId);

      const assetGroups: AssetGroup[] = get(data, "assetGroups");
      const lastAssetGroup: AssetGroup = getLastAssetGroup(assetGroups);
      const lastAssets: Asset[] = get(lastAssetGroup, "assets");
      const gltfAsset = lastAssets.find(
        (lastAsset) => path.extname(lastAsset.key) === ".gltf"
      );
      if (!gltfAsset) {
        throw new Error("GLTF 파일을 찾을 수 없습니다.");
      }

      const body = await loadGltf(lastAssetGroup.key, gltfAsset);
      if (gltfAsset.secret && gltfAsset.iv) {
        try {
          const result = await decryptGltf(
            body,
            gltfAsset.secret,
            gltfAsset.iv
          );
          setSource(result);
        } catch (e) {
          throw e;
        }
      }

      setScene(data);
    })();
  }, [hashId, isAvailableCreateHit]);

  const isAvailable = useMemo(
    () => scene !== null && source !== null,
    [scene, source]
  );

  return (
    <Fragment>
      {isAvailable && <ModelViewer source={source!} scene={scene!} isDirectOpen={isDirectOpen} isSuspendUsdz={isSuspendUsdz} isAndroid={isAndroid} />}
      {isDesktop &&
        <Fragment>
          <button className={styles.qrcodeButton} onClick={() => {
            setVisibleQrcode(prevState => !prevState);
          }}>
            <Ink />
          </button>
          <div className={classnames(styles.qrcode, visibleQrcode && styles.isVisible)}>
            <div className={styles.image}>
              <QRCode value={window.location.href} size={93} />
            </div>
            <div className={styles.guide}>
              Scan Code
            </div>
          </div>
        </Fragment>
      }
      {isSuspendUsdz && (
        <div className={styles.toSafari}>
          <div className={classnames(styles.copyMessage, copied && styles.isVisible)}>주소가 복사되었습니다. Safari에서 열어주세요.</div>
          <button onClick={() => {
            copy(window.location.href);
            setCopied(true);
          }}>주소 복사하기</button>
        </div>
      )}
    </Fragment>
  );
};

export default Viewer;
