import { Fragment, FunctionComponent, h } from "preact";
import { AssetGroup, Scene } from "../cores/schema";
import { useEffect, useLayoutEffect, useRef, useState } from "preact/hooks";
import get from "lodash.get";
import path from "path";
import { getLastAssetGroup } from "../cores/getLastAssetGroup";
import styles from './ModelViewer.module.scss';
// @ts-ignore
import urljoin from "url-join";
import { GRAPHQL_HOST, MEDIA_HOST, REDIRECT_HOST } from "../cores/constants";
import isMobile from "is-mobile";

interface Props {
  source: string;
  scene: Scene;
  isDirectOpen: boolean;
  isSuspendUsdz: boolean;
  isAndroid: boolean;
}

interface Attributes {
  ar: boolean;
  alt: string;
  poster: string;
  scale: string;
  orientation: string;
  "shadow-intensity": string;
  "shadow-softness": string;
  exposure: string;
  "ar-placement": string;
  "ar-scale": string;
  "ar-modes": string;
  "ios-src": string;
  "field-of-view": string;
  "min-field-of-view": string | number;
  "max-field-of-view": string | number;
  "interpolation-decay": string | number;
  "environment-image": string;
  "camera-controls": boolean;
}

const ModelViewer: FunctionComponent<Props> = ({
  scene,
  source,
  isDirectOpen,
  isSuspendUsdz,
  isAndroid,
}) => {
  const ref = useRef<any | null>(null);
  const [isVisibleConfirm, setVisibleConfirm] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const [attributes, setAttributes] = useState<Partial<Attributes>>({
    ar: true,
    alt: scene.description || "",
    orientation: scene.orientation.join(" "),
    scale: scene.scale.join(" "),
    "shadow-intensity": scene.shadowIntensity.toString(),
    "shadow-softness": scene.shadowSoftness.toString(),
    exposure: scene.exposure.toString(),
    "ar-placement": scene.arPlacement.toString(),
    "ar-scale": scene.arScale.toString(),
    "ar-modes": isSuspendUsdz ? "webxr scene-viewer" : "webxr scene-viewer quick-look",
    "environment-image": "neutral",
    "camera-controls": true,
    "field-of-view": scene.fieldOfView,
    "min-field-of-view": scene.minFieldOfView,
    "max-field-of-view": scene.maxFieldOfView,
  });

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const nextState: Partial<Attributes> = {};
    const assetGroups: AssetGroup[] = get(scene, "assetGroups");
    const lastAssetGroup: AssetGroup = getLastAssetGroup(assetGroups);
    const usdzAsset = lastAssetGroup.assets.find(
      (asset) => path.extname(asset.key) === ".usdz"
    );

    if (source) {
      console.log(ref.current);
      ref.current?.updateScene({
        baseURL: urljoin(MEDIA_HOST, "models", lastAssetGroup.key),
        baseModel: source,
      });
    }

    if (scene.thumbnail) {
      nextState.poster = urljoin(MEDIA_HOST, "thumbnails", scene.thumbnail);
    }

    if (!isSuspendUsdz && usdzAsset) {
      nextState["ios-src"] = urljoin(
        MEDIA_HOST,
        "models",
        lastAssetGroup.key,
        usdzAsset.key,
        scene.isAvailableTemplate ? `#custom=${GRAPHQL_HOST}/scenes/${scene.hashId}/template.html` :  ''
      );
    }

    setAttributes((prevState) => {
      return {
        ...prevState,
        ...nextState,
      };
    });

    const handleQuickLookButtonTapped = () => {
      if (scene.externalLink) {
        window.location.href = scene.externalLink;
      }
    };

    const handleMessage = ({ data: { type } }: MessageEvent) => {
      switch (type) {
        case "toDataURL":
          if (window.parent) {
            window.parent.postMessage(
              {
                type: "toDataURL",
                data: ref.current.toDataURL("image/png", 1),
              },
              "*"
            );
          }
          break;
        case "activateAR":
          ref.current["selectARMode"]();
          if (ref.current["canActivateAR"]) {
            ref.current.activateAR();
          }
          break;
      }
    };

    window.addEventListener("message", handleMessage);
    ref.current.addEventListener(
      "quick-look-button-tapped",
      handleQuickLookButtonTapped
    );

    return () => {
      window.removeEventListener("message", handleMessage);
      if (ref.current) {
        ref.current.removeEventListener(
          "quick-look-button-tapped",
          handleQuickLookButtonTapped
        );
      }
    };
  }, [scene, source, isSuspendUsdz]);

  useEffect(() => {
    if (!isDirectOpen || !ref.current) {
      return;
    }

    const interval = window.setInterval(() => {
      if (ref.current.loaded) {
        clearInterval(interval);

        if (!ref.current.arModeIsNone()) {
          if (isAndroid) {
            return setVisibleConfirm(true);
          } else {
            ref.current.activateAR();
          }
        } else {
          ref.current.activateAR();
        }

      }
    }, 100);

  }, [isDirectOpen]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (ref.current) {
        if (!ref.current) {
          return;
        }

        const status = ref.current.getAttribute('ar-status');
        setPlaying(status !== 'not-presenting' && status !== 'failed');
      }
    }, 100);

    return () => {
      clearInterval(interval);
    }
  }, []);

  return <Fragment>
    {h(
      "model-viewer",
      { ref, ...attributes },
      (!isPlaying && isMobile() && !isSuspendUsdz) && h("button", {
        slot: 'ar-button',
        className: styles.arButton,
        onClick: () => {
          const link = `${window.location.href}?is_direct_open=true`;

          try {
            if (window.webkit) {
              window.webkit.messageHandlers.outlink.postMessage(link);
            } else if (window.androidJavaScript) {
              window.androidJavaScript.outlink(link);
            } else {
              ref.current?.activateAR();
            }
          } catch (e) {
            ref.current?.activateAR();
          }
        }
      }, null))}
    {isVisibleConfirm && (
      <div className={styles.confirm}>
        <button onClick={() => {
          const interval = window.setInterval(() => {
            if (ref.current.loaded) {
              clearInterval(interval);
              setVisibleConfirm(false);
              ref.current.activateAR();
            }
          }, 100);
        }}>AR 체험하기</button>
      </div>
    )}
  </Fragment>;
};

export default ModelViewer;
