import { ReducerMapValue } from "redux-actions";
import { createAsyncTypes } from "~/cores/createAsyncTypes";

interface AsyncReducerMap<State, Success, Failure> {
  onSuccess?: ReducerMapValue<State, Success>;
  onFailure?: ReducerMapValue<State, Failure>;
}

export const createAsyncReducerMap = <
  State extends any,
  Success = any,
  Failure = Error
  >(
  type: string,
  asyncReducerMap: AsyncReducerMap<State, Success, Failure>
) => {
  const { SUCCESS, FAILURE } = createAsyncTypes(type);
  const reducerMap: { [key: string]: ReducerMapValue<State, Success> | ReducerMapValue<State, Failure> } = {};

  if (asyncReducerMap.onSuccess) {
    reducerMap[SUCCESS] = asyncReducerMap.onSuccess;
  }

  if (asyncReducerMap.onFailure) {
    reducerMap[FAILURE] = asyncReducerMap.onFailure;
  }

  return reducerMap;
};
