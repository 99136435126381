import { applyMiddleware, createStore } from '@reduxjs/toolkit';
import reducers from '~/reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '~/sagas';

const sagaMiddleware = createSagaMiddleware();

export const configureStore = <T = any>(initialState?: T) => {
  const store = createStore(
    reducers,
    initialState,
    applyMiddleware(sagaMiddleware),
  );

  sagaMiddleware.run(rootSaga);

  return store;
};