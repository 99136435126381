export const GET_ANONYMOUS_SCENE = `query ($hashId: ID!) {
  getAnonymousScene(hashId: $hashId) {
    hashId
    name
    scale
    thumbnail
    orientation
    externalLink
    shadowIntensity
    shadowSoftness
    exposure
    arPlacement
    arScale
    fieldOfView
    minFieldOfView
    maxFieldOfView
    isAvailableTemplate
    assetGroups {
      key
      assets {
        key
        iv
        secret
      }
    }
  }
}`
