import { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncTypes } from "~/cores/createAsyncTypes";

export const createAsyncAction = <
  T extends string,
  F extends (...args: any) => any
>(
  type: T,
  asyncFunc: F
): ((...args: Parameters<F>) => PayloadAction<T, Parameters<F>>) => {
  const { REQUEST, SUCCESS, FAILURE } = createAsyncTypes(type);

  return (...args: Parameters<F>) => {
    return {
      type: REQUEST,
      payload: [() => asyncFunc(...args), SUCCESS, FAILURE],
    } as any;
  };
};
