import axios from "axios";
import { CREATE_HIT } from "./mutations";
import { GET_ANONYMOUS_SCENE } from "./queries";
import { Asset } from "./schema";
import get from "lodash.get";
import path from "path";
// @ts-ignore
import urljoin from "url-join";
import { MEDIA_HOST } from "./constants";
import { cacheAdapterEnhancer } from "axios-extensions";

export const createHit = async (hashId: string) => {
  try {
    const { data } = await axios.post("/graphql", {
      query: CREATE_HIT,
      variables: {
        data: { scene: hashId },
      },
    });

    return get(data, ["data", "createHit"]);
  } catch (e) {
    throw e;
  }
};

export const getAnonymousScene = async (hashId: string) => {
  try {
    const { data } = await axios.post("/graphql", {
      query: GET_ANONYMOUS_SCENE,
      variables: {
        hashId,
      },
    });

    return get(data, ["data", "getAnonymousScene"]);
  } catch (e) {
    throw e;
  }
};

export const loadGltf = async (key: string, asset: Asset) => {
  if (path.extname(asset.key) !== ".gltf") {
    throw new Error("GLTF 파일이 아닙니다.");
  }

  try {
    const { data } = await axios.get(
      urljoin(MEDIA_HOST, "models", key, asset.key),
      {
        withCredentials: false,
        adapter: cacheAdapterEnhancer(axios.defaults.adapter!),
      }
    );
    return data;
  } catch (e) {
    throw new Error("GLTF 파일을 찾을 수 없습니다.");
  }
};

export const decryptGltf = async (body: string, secret: string, iv: string) => {
  const module = await import("../decipher/pkg");
  return module.read(body, secret, iv);
};
