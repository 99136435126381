import { all, call, put, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { ASYNC_ACTION_PREFIX } from "~/cores/createAsyncTypes";


export default function* () {
  yield all([watchAsyncActions()]);
}

function* watchAsyncActions() {
  yield takeEvery("*", function* <
    T
  >({ type, payload }: PayloadAction<[() => Promise<T>, string, string]>) {
    if (!type.startsWith(ASYNC_ACTION_PREFIX) || !type.endsWith("/REQUEST")) {
      return;
    }

    try {
      const result: unknown = yield call(payload[0]);
      yield put({ type: payload[1], payload: result });
    } catch (e) {
      yield put({ type: payload[2], payload: e });
    }
  });
}
